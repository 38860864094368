import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPipe } from '../../pipes/search.pipe';
import { PhonePrinterPipe } from '../../pipes/phone-printer.pipe';
import { PricePipe } from '../../pipes/price.pipe';
import { PaginatorPipe } from '../../pipes/paginator.pipe';
import { NameFilterPipe } from '../../pipes/name-filter.pipe';
import { TimespanPipe } from '../../pipes/timespan.pipe';
import { OrdinalPipe } from '../../pipes/ordinal.pipe';
import { SelectSearchPipe } from 'src/app/pipes/select-search.pipe';
import { IncludesPipe } from '../../pipes/includes.pipe';
import { NotIncludesPipe } from '../../pipes/not-includes.pipe';
import { TourOptionsFilterPipe } from '../../pipes/tour-options-filter.pipe';
import { TourOptionHighestPriorityPipe } from '../../pipes/tour-option-highest-priority.pipe';
@NgModule({
  imports: [CommonModule],
  exports: [
    SearchPipe,
    PhonePrinterPipe,
    PricePipe,
    PaginatorPipe,
    NameFilterPipe,
    TimespanPipe,
    OrdinalPipe,
    SelectSearchPipe,
    IncludesPipe,
    NotIncludesPipe,
    TourOptionsFilterPipe,
    TourOptionHighestPriorityPipe
  ],
  declarations: [
    SearchPipe,
    PhonePrinterPipe,
    PricePipe,
    PaginatorPipe,
    NameFilterPipe,
    TimespanPipe,
    OrdinalPipe,
    SelectSearchPipe,
    IncludesPipe,
    NotIncludesPipe,
    TourOptionsFilterPipe,
    TourOptionHighestPriorityPipe
  ],
  providers: [SearchPipe],
})
export class PipesModule {}
