import { Pipe, PipeTransform } from '@angular/core';
import { TourOptions } from '../types/leasing-fallbacks';

@Pipe({
  name: 'tourOptionHighestPriority'
})
export class TourOptionHighestPriorityPipe implements PipeTransform {

  transform(tourOptions: TourOptions): string[] {
    if (!tourOptions) {
      return []; 
    }
    // Filter and map options with priority 1 and allowed status
    return Object.entries(tourOptions)
      .filter(([_, option]) => option?.priority === 1 && option?.allowed)
      .map(([key]) => this.capitalizeFirstLetter(key));
  }
  
  private capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}