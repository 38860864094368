import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './modules/material/material.module';
import { PipesModule } from './modules/pipes/pipes.module';
import { MainGuard } from './guards/main.guard';
import { AccountGuard } from './guards/account.guard';

import { ToolsService } from './providers/tools.service';
import { MainService } from './providers/main.service';

import { HttpService } from './providers/http.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { PromptComponent } from './components/modals/prompt/prompt.component';
import { ConfirmComponent } from './components/modals/confirm/confirm.component';
import { LoadingComponent } from './components/modals/loading/loading.component';
import { AlertComponent } from './components/modals/alert/alert.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalHttpInterceptorService } from './providers/global-http-interceptor.service';
import { AngularFireRemoteConfigModule, SETTINGS } from '@angular/fire/compat/remote-config';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AppService } from './providers/app.service';

export function initializeApp(appService: AppService) {
  return () => appService.initConfig();
}

@NgModule({
  declarations: [AppComponent, PromptComponent, ConfirmComponent, LoadingComponent, AlertComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PipesModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.FIREBASE_CREDENTIAL),
    AngularFireRemoteConfigModule,
  ],
  providers: [
    PromptComponent,
    ConfirmComponent,
    LoadingComponent,
    AlertComponent,
    MainService,
    ToolsService,
    HttpService,
    AccountGuard,
    MainGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppService],
      multi: true,
    },
    { provide: SETTINGS, useValue: { minimumFetchIntervalMillis: 3600000 } },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
