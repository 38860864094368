<button class="position-absolute opacity-0" mat-icon-button></button>
<div class="row d-flex justify-content-end">
  <div class="col p-title">Payment plan</div>
  <div class="col text-end">
    <button
      (click)="dialogRef.close(hasChanged ? data.paymentPlan : false)"
      mat-icon-button
      class="square-icon-button example-icon"
    >
      <i class="fa fa-times"></i>
    </button>
  </div>
</div>
<div [class.debtCollectionInstallments]="data.type === propertyManagementFeature.DEBT_COLLECTION">
  <div class="payment-box-container box-shadow-bottom">
    <div *ngFor="let transaction of transactions; index as Index" class="row justify-content-md-center">
      <div *ngIf="!isReadOnly">
        <mat-form-field appearance="outline" class="right-10px col-md-5 ps-0 pe-0">
          <mat-label>Payment</mat-label>
          <mat-icon class="console-icon-color" matSuffix>attach_money</mat-icon>
          <input type="number" matInput placeholder="Payment" [(ngModel)]="transaction.balance" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-5 ps-0">
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker" [min]="today" [(ngModel)]="transaction.date" />
          <mat-datepicker-toggle class="console-icon-color" matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button
          *ngIf="Index === 0 && !transaction.date && !transaction.balance"
          class="col-md-1 ps-0 opacity-0"
          mat-icon-button
        >
          <i class="console-icon-color fas fa-trash-alt"></i>
        </button>
        <button
          *ngIf="Index === 0 && transaction && (transaction.date || transaction.balance)"
          (click)="removePaymentPlan(Index)"
          class="minus-right-10px col-md-1 ps-0 console-icon-color"
          mat-icon-button
        >
          <i class="console-icon-color fas fa-trash-alt"></i>
        </button>
        <button
          *ngIf="Index !== 0"
          class="col-md-1 ps-0 minus-right-10px"
          mat-icon-button
          (click)="removePaymentPlan(Index)"
        >
          <i class="console-icon-color fas fa-trash-alt"></i>
        </button>
      </div>
      <div *ngIf="isReadOnly">
        <mat-form-field appearance="outline" class="right-10px col-md-6">
          <mat-label>Payment</mat-label>
          <mat-icon class="console-icon-color" matSuffix>attach_money</mat-icon>
          <input readonly type="number" matInput placeholder="Payment" [(ngModel)]="transaction.balance" />
          <mat-error>This field is required</mat-error>
          <mat-error>This field is <strong>required</strong></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>date</mat-label>
          <input readonly matInput [min]="today" [matDatepicker]="picker" [(ngModel)]="transaction.date" />
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="!isReadOnly">
      <div class="col">
        <button
          class="console-icon-color minus-right-10px add-payment-button"
          (click)="addPaymentPlan()"
          mat-icon-button
        >
          <i class="far fa-plus-circle cursor-pointer pe-2"></i><span class="pt-1">Add payments</span>
        </button>
      </div>
    </div>
  </div>
  <div class="row justify-content-end page-content pt-2">
    <div class="col-md-12">
      <mat-error *ngIf="isError">These fields are required</mat-error>
      <mat-dialog-actions align="end">
        <button
          *ngIf="!isReadOnly"
          class="custom-button console-button-color-bg console-content-color"
          (click)="save()"
          mat-button
        >
          Save
        </button>
        <button *ngIf="!isReadOnly" mat-button color="primary" (click)="doCancel()">Cancel</button>
        <button
          *ngIf="isReadOnly"
          class="custom-button console-button-color-bg console-content-color"
          mat-button
          (click)="isReadOnly = false"
        >
          Edit
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</div>
