import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EMPTY_STRING } from '../../../const/const';
import { HttpService } from '../../../providers/http.service';
import { ToolsService } from '../../../providers/tools.service';
import { MainService } from '../../../providers/main.service';
import { Roles } from '../../../const/const';
import { PaymentPlanResponse, PaymentPlanTransactions } from '../../../types/conversations/payment-plan.model';
import { PropertyManagementFeature } from '../../../types/property-management-feature';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import datesUtils from 'src/app/utils/dates.utils';

@UntilDestroy()
@Component({
  selector: 'app-payment-plan',
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.scss'],
})
export class PaymentPlanComponent implements OnInit {
  transactions: PaymentPlanTransactions[] = [];
  public propertyManagementFeature = PropertyManagementFeature;
  public today = new Date();

  constructor(
    public dialogRef: MatDialogRef<PaymentPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: HttpService,
    public tools: ToolsService,
    public main: MainService
  ) {}

  isError: boolean = false;
  isReadOnly: boolean = true;
  hasChanged: boolean = false;

  public ngOnInit(): void {
    this.loadPayments();
  }

  private loadPayments(): void {
    this.transactions = JSON.parse(JSON.stringify(this.data?.paymentPlan?.transactions || []));
  }

  public addPaymentPlan(): void {
    let collectedBalance = 0;
    this.transactions.forEach(({ balance }) => (collectedBalance += balance));
    const balance = this.data.balance - collectedBalance;
    this.transactions.push({ balance, date: EMPTY_STRING });
  }

  public removePaymentPlan(index: number): void {
    this.transactions.splice(index, 1);
  }

  public save(): void {
    this.isError = false;
    const isValid = this.checkFormValues();
    if (!isValid) {
      this.isError = true;
      return;
    }
    this.changeDatesToYYYMMDDFormat();
    if (this.data.paymentPlan?._id) {
      this.updatePaymentPlan();
    } else {
      this.setPaymentPlan();
    }
  }

  private updatePaymentPlan(): void {
    const data = {
      paymentPlanID: this.data.paymentPlan._id,
      transactions: this.transactions,
      modifiedByUserID: this.main.userID,
      modifiedByUserType: Roles.SUPERVISOR,
      modifiedDate: new Date(),
    };
    this.http
      .$httpPut(`${this.tools.NS_URL}/payment-plan`, data)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: { data: PaymentPlanResponse }) => {
          this.transactions = res.data.transactions || [];
          this.data.paymentPlan = JSON.parse(JSON.stringify(res.data));
          this.hasChanged = true;
          this.isReadOnly = true;
          this.tools.toast('Payment plan was successfully updated');
        },
        () => {
          this.tools.loading(false);
          this.tools.toast('Something went wrong');
        }
      );
  }

  private setPaymentPlan(): void {
    const data = {
      tenantID: this.data.tenantID,
      transactions: this.transactions,
      createdByUserID: this.main.userID,
      createdByUserType: Roles.SUPERVISOR,
    };
    this.http
      .$httpPost(`${this.tools.NS_URL}/payment-plan`, data)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: { data: PaymentPlanResponse }) => {
          this.tools.toast('Payment plan was successfully saved');
          this.transactions = res.data.transactions || [];
          this.data.paymentPlan = JSON.parse(JSON.stringify(res.data));
          this.hasChanged = true;
          this.isReadOnly = true;
        },
        () => {
          this.tools.loading(false);
          this.tools.toast('Something went wrong');
        }
      );
  }

  private checkFormValues(): boolean {
    let isValid = true;
    if (this.transactions.length > 0) {
      for (const transaction of this.transactions) {
        if (!transaction.balance || !transaction.date) {
          isValid = false;
        }
      }
    }
    return isValid;
  }

  public doCancel(): void {
    this.isReadOnly = true;
    this.isError = false;
    this.loadPayments();
  }

  private changeDatesToYYYMMDDFormat(): void {
    this.transactions.forEach((transaction) => {
      if (transaction.date) {
        transaction.date = datesUtils.getUTCDateInYearMonthDayFormatAsString(transaction.date);
      }
    });
  }
}
