import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { YamlActionsPopupData } from '../../types';
import { ColorTypes } from '../../const/const';

@Component({
  selector: 'app-yaml-actions-popup',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './yaml-actions-popup.component.html',
  styleUrls: ['./yaml-actions-popup.component.scss'],
})
export class YamlActionsPopupComponent {
  public ColorTypes = ColorTypes;
  constructor(
    public dialogRef: MatDialogRef<YamlActionsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: YamlActionsPopupData
  ) {}
}
