import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { getUrlCompressedParams } from '../utils/compression.utils';
import * as CryptoJS from 'crypto-js';
import { AppService } from './app.service';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(
    public router: Router,
    private appService: AppService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.addAuthHeader(req);
    if (req.headers.get('x-compressed-params') === 'true') {
      req = this.addCompressedParams(req, req.urlWithParams);
    }
    return next.handle(req).pipe(
      catchError((error) => {
        if (error?.status === 403 || error?.status === 401) {
          console.error(error);
          localStorage.clear();
          this.router.navigateByUrl('/account/login');
          return throwError(error.message);
        }
        return throwError(error);
      })
    );
  }

  private getHmacSignature(data: any): string {
    const rawBody = JSON.stringify(data);
    return CryptoJS.HmacSHA256(rawBody, this.appService.ENV_CONFIG.HMAC_VALIDATION_SECRET).toString();
  }

  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const setHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };

    if (request.body) setHeaders['X-Hmac'] = this.getHmacSignature(request.body);

    if (request.body instanceof FormData) {
      return request.clone({
        setHeaders,
      });
    } else {
      return request.clone({
        setHeaders: {
          ...setHeaders,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    }
  }

  // Function to add compressed parameters to the request URL
  private addCompressedParams(req: HttpRequest<any>, url: string): HttpRequest<any> {
    // Get the updated URL with compressed parameters
    const compressedParams = getUrlCompressedParams(url);
    // Clone the request and update its URL and parameters
    return req.clone({
      url: url.split('?')[0],
      params: new HttpParams({ fromObject: compressedParams }), // Set the compressed parameters
    });
  }
}
