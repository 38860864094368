<div class="yaml-actions-container">
  <button (click)="dialogRef.close(false)" class="close-button" matRipple>
    <img src="./assets/images/close-icon.svg" />
  </button>
  <div class="conversation">
    <div class="header">
      <h1 class="title">Possible actions</h1>
      <div class="subtitle">List of actions related to the selected answer</div>
    </div>
    <div class="body">
      <div class="messages">
        <div class="message" *ngFor="let action of data.actions">
          <div class="text">
            <span>{{ action }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button
        mat-button
        class="console-content-color"
        [ngClass]="{
          'main-btn-color': data.colorType === ColorTypes.ORANGE,
          'purple-btn': data.colorType === ColorTypes.PURPLE,
          'blue-btn': data.colorType === ColorTypes.BLUE,
          'light-blue-btn': data.colorType === ColorTypes.LIGHT_BLUE
        }"
        (click)="dialogRef.close(false)"
      >
        Ok
      </button>
    </div>
  </div>
</div>
